import SanityClientConstructor from '@sanity/client'
import produce from 'immer'

import { StoreActionTypes, StoreActions } from '../store/types'
import { SystemActionTypes, SystemActions, SystemState } from './types'

// Action creators

export const cartVisibleSet = (cartVisible: boolean): SystemActions => ({
  payload: {
    cartVisible,
  },
  type: SystemActionTypes.CART_VISIBLE_SET,
})

export const debugSet = (debug: boolean): SystemActions => ({
  payload: {
    debug,
  },
  type: SystemActionTypes.DEBUG_SET,
})

/*
export const menuVisibleSet = (visible: boolean): SystemActions => ({
  payload: {
    visible,
  },
  type: SystemActionTypes.MENU_VISIBLE_SET,
})
*/

// Reducer

export const initialState = {
  cartText: undefined,
  cartVisible: false,
  debug: false,
  footer: undefined,
  // menuVisible: false,
  meta: undefined,
  sanity: undefined,
}

export default function systemReducer(
  state: SystemState = initialState,
  action: StoreActions | SystemActions
): SystemState {
  return produce(state, draft => {
    switch (action.type) {
      // Store actions
      case StoreActionTypes.CHECKOUT_LINE_ITEM_ADD_COMPLETE:
        draft.cartVisible = true
        break

      // System actions
      case SystemActionTypes.CART_VISIBLE_SET:
        draft.cartVisible = action.payload?.cartVisible
        break
      case SystemActionTypes.DEBUG_SET:
        draft.debug = action.payload?.debug
        break
      /*
      case SystemActionTypes.MENU_VISIBLE_SET:
        draft.menuVisible = action.payload?.visible
        break
      */
    }
  })
}
