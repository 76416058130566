import { SanityBlock, SanityImage } from '@types'

// Action types

export enum SystemActionTypes {
  CART_VISIBLE_SET = 'SYSTEM_CART_VISIBLE_SET',
  DEBUG_SET = 'SYSTEM_DEBUG_SET',
  // MENU_VISIBLE_SET = 'SYSTEM_MENU_VISIBLE_SET',
}

// Actions

type CartVisibleSet = {
  payload: {
    cartVisible: boolean
  }
  type: typeof SystemActionTypes.CART_VISIBLE_SET
}

type DebugSet = {
  payload: {
    debug: boolean
  }
  type: typeof SystemActionTypes.DEBUG_SET
}

/*
type MenuVisibleSet = {
  payload: {
    visible: boolean
  }
  type: typeof SystemActionTypes.MENU_VISIBLE_SET
}
*/

export type SystemActions = CartVisibleSet | DebugSet

// Reducer

export type SystemState = {
  cartText?: SanityBlock[]
  cartVisible: boolean
  debug: boolean
  footer?: {
    _type: string
    title: string
  }[]
  // menuVisible: boolean
  meta?: {
    description: string
    image: SanityImage
    title: string
  }
}
