import { combineReducers } from 'redux'

import store from './store'
import system from './system'

export const rootReducer = combineReducers({
  store,
  system, //
})

export type RootReducerState = ReturnType<typeof rootReducer>
