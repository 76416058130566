import React from 'react'

import Box from '../Box'
import TextBody from '../TextBody'

type Props = {}

const MaintenancePage = (props: Props) => {
  return (
    <Box p={2}>
      <TextBody>This site is currently down for maintenance</TextBody>
    </Box>
  )
}

export default MaintenancePage
