// import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { AppProps } from 'next/app'
import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import 'normalize.css'

import GlobalStyle from '../components/GlobalStyle'
import Main from '../components/Main'
import MaintenancePage from '../components/MaintenancePage'
import settings from '../deps/settings'
// import { useApollo } from '../lib/apolloClient'
import { initStore } from '../redux/store'
import { initialState as storeInitialState } from '../redux/modules/store'
import { initialState as systemInitialState } from '../redux/modules/system'
import '../global.css'
import theme from '../styled/theme'

type Props = AppProps & {}

// Initialise Sentry (client only)
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.VERCEL_GITHUB_COMMIT_SHA,
  })
}

const MyApp = (props: Props) => {
  const {
    Component,
    pageProps,
    // router
  } = props

  // Initialize redux store with preloaded state
  const [store] = useState(
    initStore({
      store: {
        ...storeInitialState,
        // @ts-ignore
        ...settings.store,
      },
      system: {
        ...systemInitialState,
        // @ts-ignore
        ...settings.settings,
      },
    })
  )

  /*
  // Initialise apollo client
  const apolloClient = useApollo(pageProps.initialApolloState)
  */

  /*
  useEffect(() => {
    // Send gtag pageview on route change
    router.events.on('routeChangeComplete', url => {
      setTimeout(() => {
        if (window.gtag) {
          window.gtag('event', 'page_view')
        }
      }, 100)
    })
  }, [])
  */

  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === '1'

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* Global styles */}
        <GlobalStyle />

        {isMaintenance ? (
          <MaintenancePage />
        ) : (
          <Main Component={Component} pageProps={pageProps} />
        )}
      </ThemeProvider>
    </Provider>
  )
}

export default MyApp
